<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Domeinnaam doorsturen: doorverwijzen naar een andere website</h1>
        <CheckDomainInput/>
        <div>
          <p>
            <img
              class="framed-image"
              style="float: right; margin-left: 20px;"
              src="../../assets/images/cloudservers.png"
              height="184"
              width="300"
              title="domeinnaam doorsturen, domeinnaam doorverwijzen"
              alt="domeinnaam doorsturen, domeinnaam doorverwijzen"
            >
            <i>“We hebben diverse domeinnamen geregistreerd om die te laten doorverwijzen naar ons hoofddomein. Heel eenvoudig en goed geregeld. Fijn ook dat jullie specialist meteen even hielp bij onze technische vragen,”</i> aldus een tevreden klant.
          </p>
          <p>Een domeinnaam doorsturen biedt uitkomst wanneer u een URL registreert die u (nog) niet wilt gebruiken voor een eigen website of wanneer u typefouten door bezoekers wilt ondervangen. Registreer meerdere domeinnamen op basis van de adressen die gebruikers intypen en verwijs die door naar uw hoofddomein.</p>
          <p>Een doorverwijzing voor een domeinnaam kan bovendien uitkomst bieden indien u bekend bent onder verschillende bedrijfsnamen of op het moment dat u inmiddels een nieuwe bedrijfsnaam heeft. Wat de reden ook is, wij kunnen de domeinnaam doorverwijzen naar een andere website en er daarmee voor zorgen dat bezoekers netjes bij u terecht zullen komen.</p>
        </div>
        <h2>Domein forwarden</h2>
        <p>Uw voordelen bij Hosting on Demand</p>
        <ul>
          <li>Direct uw domeinnaam doorverwijzen</li>
          <li>Goedkope registratie van domeinnamen</li>
          <li>Uitstekende technische ondersteuning</li>
        </ul>
        <p>We maken het u op die manier graag eenvoudig om een domein door te verwijzen of te forwarden. Het zijn benamingen voor dezelfde oplossing, waarmee we ervoor zorgen dat bezoekers op het juiste domein terecht zullen komen. Zodra zij een oude of verkeerde URL intypen die u heeft geregistreerd zorgen wij ervoor dat die uw website toch gewoon zullen bereiken. U voorkomt daarmee dat u bezoekers mis zou lopen.</p>
        <p>Een domein doorverwijzen is technisch relatief eenvoudig, onze specialisten zorgen ervoor dat ze dit meteen in orde maken. U kunt online een domeinnaam registreren en aangeven dat u die graag wilt doorverwijzen. Gebruikt u al een <router-link to="/">domeinnaam en hostingpakket</router-link> voor een andere website en wilt u die URL voortaan gaan doorverwijzen? Ook dat is geen probleem, wij maken het graag voor u in orde.</p>
        <h2>Domeinnaam doorverwijzen</h2>
        <p>We zullen de domeinnaam doorverwijzen. Door het domein door te sturen komen bezoekers uiteindelijk op de juiste website terecht, zonder dat ze daar zelf iets van hoeven te merken of moeite voor hoeven te doen.</p>
        <p>Heeft u vragen over het doorverwijzen van een domein of het forwarden van de URL? Wij zorgen er met onze technische ondersteuning voor dat u er niet alleen voor staat. In plaats daarvan begeleiden we u graag bij het doorsturen van de domeinnaam, zodat uw hoofddomein de locatie vormt waar bezoekers terecht komen, ze uw diensten of producten kunnen ontdekken en uiteraard contact met u kunnen opnemen.</p>
        <RandomQuote/>
      </div>
      <DomainNameRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DomainNameRightSideBlocks from '@/components/DomainNameRightSideBlocks.vue';
import CheckDomainInput from '@/components/CheckDomainInput.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'ForwardDomainName',
  components: {
    Breadcrumbs,
    DomainNameRightSideBlocks,
    CheckDomainInput,
    RandomQuote,
  },
}
</script>